<template>
	<v-container class="pa-0 indigo " style="height: 100%;" >
	
		<div
		>
			<div
				class="text-center"
				style="padding: 100px 0 50px; color: white;"
			>	
				<div v-if="step == 1" class="subtitle-1">신규 핀번호를 입력하세요</div>
				<div v-if="step == 2" class="subtitle-1">기존 핀번호를 입력하세요</div>
				<div v-if="step == 3" class="subtitle-1">핀번호를 한번 더 입력하세요</div>
				
				<div class="mt-3">
					<template
						v-for="(val, key) in pin"
					>
						<v-icon
							v-model="pin[key]"
							v-if="val != ''"
							:key="key"
							class="ma-3"
							color="teal lighten-2">mdi-checkbox-blank-circle</v-icon>
						
						<v-icon
							v-model="pin[key]"
							v-if="val == ''"
							:key="key"
							class="ma-3"
							color="grey">mdi-checkbox-blank-circle</v-icon>
					</template>
				</div>
				
			</div>
			
		</div>
			
		<div
			style="position: fixed; bottom: 0;"
		>
			
			<v-divider></v-divider>
	
			<v-row 	
				class="ma-0 white"
			>
				<template
					v-for="(val, key) in pin_number"
				>
					<v-col

						cols="4"
						v-if="key != 11"
						:key="key"
						class="text-center"
						style="border-right: 1px solid #ddd; border-bottom: 1px solid #ddd; font-size: 0.8em;"
						@click="setPin(key)"
					>
						<v-btn
							class="pa-5 ma-0 white"
							width="100%"
							text
						>{{ val }}</v-btn>
					</v-col>
					
					<v-col
						cols="4"
						v-if="key == 11"
						:key="key"
						class="text-center"
						style="border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;"
						@click="setPin(key)"
					>
						<v-btn
							class="pa-0 ma-0 white"
							text
							width="100%"
						><v-icon>{{ val }}</v-icon></v-btn>
					</v-col>
				</template>
			</v-row>
		</div>
		
	</v-container>
</template>


<script>

	import { Base64 } from 'js-base64'

	export default{
		name: 'Pin'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
		}
		,props: ['user', 'callBack']
		,data: function(){
			return {
				pin: ['', '', '', '']
				,pin_temp: ['', '', '', '']
				,pin_number: [1,2,3,4,5,6,7,8,9, '초기화', 0, 'mdi-backspace-outline']
				,program: {
					title: 'PIN 로그인 설정'
					,not_navigation: true
				}
				,step: 1
				,type: 'new'
				,pin_text: ''
				,is_ok: true
			}
		}
		,methods:{
			setPin: function(call){
				console.log('setPing : ' + call)
				if(call == 9){
					for(let i = 0; i < this.pin.length; i++){
						this.$set(this.pin, i, '')
					}
				}else if(call == 10){
					for(let i = 0; i < this.pin.length; i++){
						if(this.pin[i] == ''){
							this.$set(this.pin, i, '0')
							return false
						}else{
							console.log(this.pin[i])
						}
					}
				}else if(call == 11){
					for(let i = this.pin.length - 1; i >= 0; i--){
						if(this.pin[i] != ''){
							console.log(this.pin)
							this.$set(this.pin, i, '')
							return false
						}
					}
				}else{
					for(let i = 0; i < this.pin.length; i++){
						if(this.pin[i] == ''){
							this.$set(this.pin, i, call + 1)
							return false
						}else{
							console.log(this.pin[i])
						}
					}
				}
			}
			,checkPin: function(){
				for(let i = 0; i < this.pin.length; i++){
					if(this.pin[i] == ''){
						return false
					}
				}

				if(this.type == 'new'){
					if(this.step == 1){
						this.pin_temp = this.pin
						this.pin = ['', '', '', '']
						this.step = 3
					}else if(this.step == 3){
						for(let i = 0; i < this.pin.length; i++){
							if(this.pin[i] != this.pin_temp[i]){
								this.pin = ['', '', '', '']
								this.$emit('alertMsg', 'error', '핀번호가 동일하지 않습니다1')
								this.pin_text = '핀번호가 동일하지 않습니다'
								this.step = 1
							}else{
								this.pin_text = ''
								this.setPinLogin()
							}
						}
					}
				}else if(this.type == 'change'){
					if(this.step == 1){
						this.pin_temp = this.pin
						this.pin = ['','','','']
						this.step = 3
					}else if(this.step == 2){
						
						let pin = ''
						for(let i = 0; i < this.pin.length; i++){
							pin += this.pin[i]
						}
						
						console.log(pin + " : " + this.user.pin)
						if(pin != this.user.pin){
							this.pin_text = '핀번호가 다릅니다. 기존 핀번호를 다시 한번 입력하세요'
							this.$emit('alertMsg', 'error', this.pin_text)
							this.pin = ['', '', '', '']
						}else{
							console.log('pin match !!')
							this.pin_text = ''
							this.pin_temp = this.pin
							this.pin = ['', '', '', '']
							this.step = 1
						}
					}else if(this.step == 3){
						for(let i = 0; i < this.pin.length; i++){
							if(this.pin[i] != this.pin_temp[i]){
								this.pin = ['', '', '', '']
								this.pin_text = '핀번호가 동일하지 않습니다2'
								this.$emit('alertMsg', 'error', this.pin_text)
							}else{
								this.pin_text = ''
								this.setPinLogin()
							}
						}
					}
				}else if(this.type == 'remove'){
					
					if(this.step == 2){
						let pin = ''
						for(let i = 0; i < this.pin.length; i++){
							pin += this.pin[i]
						}
						
						console.log(pin + " : " + this.user.pin)
						if(pin != this.user.pin){
							this.pin_text = '핀번호가 다릅니다. 기존 핀번호를 다시 한번 입력하세요'
							
							this.$emit('alertMsg', 'error', this.pin_text)
							this.pin = ['', '', '', '']
						}else{
							for(let i = 0; i < this.pin.length; i++){
								if(this.pin[i] != this.pin_temp[i]){
									this.pin = ['', '', '', '']
									this.pin_text = '핀번호가 동일하지 않습니다3'
									this.$emit('alertMsg', 'error', this.pin_text)
								}else{
									this.pin_text = ''
									this.removePinLogin()
								}
							}
						}
					}else if(this.step == 3){
						for(let i = 0; i < this.pin.length; i++){
							if(this.pin[i] != this.pin_temp[i]){
								this.pin = ['', '', '', '']
								this.pin_text = '핀번호가 동일하지 않습니다4'
								this.$emit('alertMsg', 'error', this.pin_text)
							}else{
								this.pin_text = ''
								this.removePinLogin()
							}
						}
					}
				}
			}
			,removePinLogin: function(){
				console.log('removePinLogin')
				this.$emit('axios', {
					request_type: 'delete'
					,request_url: ''
					,request_params: {
					}
					,callBack: 'removeResult'
					,dev: 'success'
				})
			}
			,setPinLogin: function(){
				console.log('setPinLogin')
				try{
					window.Android.setPinCodeUse('Y')
					window.Android.setPinCode(this.pin.join(''))
				}catch(e){
					localStorage.setItem('paylinkp', 'Y')
					localStorage.setItem('paylinkc', Base64.encode(this.pin.join('')))
					console.log(e)
				}finally{
					this.setResult()
				}
			}
			,settingType: function(){
				console.log('settingType : ' + this.type)
				if(this.type == 'new'){
					this.program.title = 'PIN 로그인 설정'
					this.$delete(this.program, 'next')
				}else if(this.type == 'change'){
					this.program.title = 'PIN 로그인 재설정'
					this.$set(this.program, 'next', {
						title: '해제'
						,do: true
						,callBack: 'setTypeRemove'
					})
				}else if(this.type == 'remove'){
					this.program.title = 'PIN 로그인 해제'
					this.$delete(this.program, 'next')
				}
			}
			,settingStep: function(){
				//
			}
			,setTypeRemove: function(){
				this.type = 'remove'
				this.step = 2
			}
			,removeResult: function(){
				this.$emit('modal', {
					title: ''
					,contents: 'PIN로그인이 해제되었습니다'
					,contents_type: 'text'
					,accept: '확인'
					,to: '/Mypage'
					,icon: 'mdi-check-bold'
					,icon_color: 'success'
				})
			}
			,setResult: function(){
				if(this.is_ok) {
					this.$emit('modal', {
						title: ''
						, contents: 'PIN로그인이 설정되었습니다'
						, contents_type: 'text'
						, accept: '확인'
						, to: '/Mypage'
						, icon: 'mdi-check-bold'
						, icon_color: 'success'
					})
				}
			}
		}
		,watch:{
			pin: {
				deep: true
				,handler: 'checkPin'
			}
			,type: {
				immediate: true
				,handler: function(){
					this.settingType()
				}
			}
			,step: {
				handler: 'settingStep'
			}
			,callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'setTypeRemove'){
						this.setTypeRemove()
					}else if(call.name == 'setResult'){
						this.setResult()
					}else if(call.name == 'removeResult'){
						this.removeResult()
					}
				}
			}
			,user: {
				deep: true
				,immediate: true
				,handler: function(call){
					if(call.pin){
						this.type = 'change'
						this.step = 2
					}
				}
			}
		}
	}
</script>

<style>
	.v-application .indigo {background-color: #15324E !important}
</style>